import axios from "axios"

export function getMasterData(customerId) {
    return axios.request({
        method: 'get',
        url: `/api/md/getMasterData/${customerId}`,
    })
}

export function pushOrder(orderData) {
    return axios.request({
        method: 'post',
        url: '/api/order/push',
        data: { ...orderData }
    })
}