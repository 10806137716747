import React, { Component } from 'react'

import {
  Form,
  Space,
  Input,
  Button,
  Dialog,
  TextArea,
  Radio,
  Toast,
  Picker,
  CascadePicker,
  DatePicker,
  Mask,
  Loading,
  Result
} from 'antd-mobile'
import './order.css'
import { getMasterData, pushOrder } from '../../api/api'
import orderImage from '../../assets/imgs/order.png'

export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      masterData: {},
      loadMasterDataSuccess: false,
      addressPickerVisible: false,
      productPickerVisible: false,
      specPickerVisible: false,
      datePickerVisible: false,
      pushLoading: false,
      productOptions: [],
      specOptions: [],
      serviceType: null,
      warrantyType: null,
      county: [],
      brand: '',
      product: [],
      spec: [],
      serviceDate: null,
    }
  }

  formRef = React.createRef()

  componentDidMount() {
    this.getData()
  }

  //获取基础数据
  async getData() {
    const res = await getMasterData(5923);
    if (res.data.code === 0) {
      this.setState({
        ...this.state,
        masterData: res.data.data,
        loadMasterDataSuccess: true,
      })
    } else {
      Dialog.alert({
        content: <Result
          status='error'
          title='加载错误'
          description='数据加载错误，请稍候重试'
        />
      });
    }
  }

  async pushOrder(orderData) {
    const res = await pushOrder(orderData);
    if (res.data.code === 0) {
      this.setState({
        serviceType: null,
        warrantyType: null,
        brand: '',
        product: [],
        spec: [],
        serviceDate: null,
      })
      this.formRef.current.setFieldsValue({ qty: "1" })
      Dialog.alert({
        content: <Result
          status='success'
          title='提交成功'
        />
      });
    } else {
      Dialog.alert({
        content: <Result
          status='error'
          title='提交失败'
          description={res.data.msg}
        />
      });
    }
    this.setState({
      pushLoading: false,
    })
  }

  getNameList(objectList) {
    const result = [];
    if (objectList) {
      objectList.map(item => {
        if (item.name.substr(0,1) !== 'T') {
          result.push(item.name);
        }
      });
    }
    return [result];
  }

  getObjectFromList(val, list) {
    var result = null;
    if (list) {
      result = list.find((value) => value.name === val.toString());
    }
    return result;
  }

  getSpecNameList(product, productList) {
    if (productList) {
      const productObject = this.getObjectFromList(product.toString(), productList);
      if (productObject) {
        return this.getNameList(productObject.specs);
      }
    }
    return [];
  }

  onAddressButtonClicked = (county) => {
    if (!county) {
      Toast.show({ content: '加载数据错误，请刷新页面或重新进入！' })
      return;
    }
    this.setState({
      addressPickerVisible: true
    })
  }

  onProductButtonClicked = (products) => {
    const productOptions = this.getNameList(products);
    if (!productOptions || productOptions[0].length === 0) {
      Toast.show({ content: '无产品' })
      return;
    }
    this.setState({
      productPickerVisible: true,
      productOptions,
    })
  }

  onSpecButtonClicked = (product, productList) => {
    if (!product || product.length === 0) {
      Toast.show({ content: '请先选择产品' });
      return;
    }
    const specOptions = this.getSpecNameList(product, productList);
    if (!specOptions || specOptions[0].length === 0) {
      Toast.show({ content: '无型号' });
      return;
    }
    this.setState({
      specPickerVisible: true,
      specOptions,
    })
  }

  onDateButtonClicked = () => {
    this.setState({
      datePickerVisible: true,
    })
  }

  onCountyConfirm = (county) => {
    this.setState({
      ...this.state,
      county,
    })
  }

  onProductConfirm = (product) => {
    this.setState({
      ...this.state,
      product,
      spec: [],
    })
  }

  onSpecConfirm = (spec) => {
    const { masterData: { products }, product } = this.state;
    const productObject = this.getObjectFromList(product.toString(), products);
    var brand = '';
    if (productObject) {
      const specObject = this.getObjectFromList(spec.toString(), productObject.specs);
      brand = specObject.brand;
    }
    this.setState({
      ...this.state,
      spec,
      brand
    })
  }

  onDateConfirm = (serviceDate) => {
    const dateString = [serviceDate.getFullYear().toString(), (serviceDate.getMonth() + 1).toString(), serviceDate.getDate().toString()].join('/');
    this.setState({
      ...this.state,
      serviceDate: dateString,
    })
  }

  //提交按钮
  onFinish = (values) => {
    const {
      serviceType,
      warrantyType,
      county,
      brand,
      product,
      spec,
      serviceDate,
    } = this.state;

    var errorMessage = '';
    if (serviceType === null) {
      errorMessage = '【服务类型】 ';
    }
    if (warrantyType === null) {
      errorMessage += '【保内/保外】 ';
    }
    if (!county || county.length === 0) {
      errorMessage += '【省/市/区 地址】 ';
    }
    if (!product || product.length === 0) {
      errorMessage += '【产品】 ';
    }
    if (errorMessage !== '') {
      Dialog.alert({
        title: '提交错误,请填写以下信息',
        content: errorMessage,
      })
      return
    }
    this.setState({
      pushLoading: true,
    })

    var confirmData = {
      ...values,
      customerId: 5923,
      county: county.join(' / '),
      serviceType,
      warrantyType,
      brand: brand.toString(),
      product: product.toString(),
      spec: spec.toString(),
      expectVisitTime: serviceDate,
    }

    this.pushOrder(confirmData);
  }


  render() {
    const {
      masterData: { products, provinces },
      loadMasterDataSuccess,
      addressPickerVisible,
      productPickerVisible,
      specPickerVisible,
      datePickerVisible,
      pushLoading,
      productOptions,
      specOptions,
      county,
      product,
      spec,
      serviceType,
      warrantyType,
      serviceDate,
    } = this.state;

    const isWechat = navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 || typeof navigator.wxuserAgent !== 'undefined';

    if (!isWechat) {
      return (
        <Result
          status='error'
          title='请在微信中打开'
          description='此链接仅支持在微信中打开，请通过公众号链接跳转'></Result>
      )
    }

    const today = new Date();
    const resultDay = new Date(today.setDate(today.getDate() + 1));

    return (
      <div className="container" >
        <div className="center">
          <div className="head">
            <img className="order_img" src={orderImage} alt="" />
            <p className="welcome">欢迎您使用快可立全国联保用户微信自助下单系统，本系统仅供用户下单使用。</p>
          </div>
        </div>
        <Form
          onFinish={values => this.onFinish(values)}
          ref={this.formRef}
          initialValues={{qty:"1"}}
          footer={
            <Button className='button' size='large' style={{ height: '50px' }} type='submit'>
              确认提交
            </Button>
          }>
          <Mask visible={!loadMasterDataSuccess}>
            <div className='overlayContent'>
              <Loading color='currentColor'>Loading</Loading>
            </div>
          </Mask>
          <Mask visible={pushLoading}>
            <div className='overlayContent'>
              <Loading color='currentColor'>数据提交中</Loading>
            </div>
          </Mask>
          <Form.Item
            name='userName'
            label='联系人'
            size="large"
            rules={[{ required: true, message: '联系人不能为空' }]}
          >
            <Input className="border_input" placeholder="请输入联系人"></Input>
          </Form.Item>
          <Form.Item
            name='userMobile'
            label='联系电话'
            rules={[{ required: true, message: '请输入长度为11的手机号码', max: 11, min: 11 }]}
          >
            <Input className="border_input" type="number" placeholder="请输入手机号码"></Input>
          </Form.Item>

          <Form.Item
            name='userPhone'
            label='其他联系方式'
            rules={[{ message: '请输入长度为11~16的联系方式', max: 16, min: 11 }]}
          >
            <Input className="border_input" type="number" placeholder="请输入其他联系方式"></Input>
          </Form.Item>

          <Form.Item
            label={
              <div><span style={{ color: 'red', marginRight: '5px' }}>*</span><span>用户地址</span></div>}
            rules={[{ required: true, message: '请选择 省/市/区' }]}
          >
            <Button
              size="large"
              style={{ width: '100%', textAlign: 'left', paddingLeft: '10px', color: `${county.length === 0 ? '#CCCCCC' : ''}` }}
              onClick={() => this.onAddressButtonClicked(county)}
            >
              {county.length === 0 ? '请选择 省/市/区' : county.join(' / ')}
            </Button>
            <CascadePicker
              options={provinces ? provinces : []}
              visible={addressPickerVisible}
              onClose={() => {
                this.setState({ addressPickerVisible: false })
              }}
              onConfirm={val => { this.setState({ county: val }) }}
              onSelect={null}
            />
          </Form.Item>
          <Form.Item
            name='address'
            label='详细地址(详细到门牌号)'
            rules={[{ required: true, message: '用户地址不能为空', min: 4 }]}
          >
            <TextArea className="border_textarea" rows="3" placeholder="请输入详细地址"></TextArea>
          </Form.Item>
          <Form.Item
            label={
              <div><span style={{ color: 'red', marginRight: '5px' }}>*</span><span>服务类型</span></div>}
            rules={[{ required: false, message: '请选择服务类型' }]}
          >
            <Space />
            <Radio.Group onChange={val => this.setState({ serviceType: val })} value={serviceType}>
              <Space direction='horizontal' style={{ marginTop: '5px' }} >
                <Radio value="INSTALL">安装</Radio>
                <Radio value="REPAIR">维修</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <div><span style={{ color: 'red', marginRight: '5px' }}>*</span><span>质保类型</span></div>}
            rules={[{ required: false, message: '请选择质保类型' }]}
          >
            <Radio.Group onChange={val => this.setState({ warrantyType: val })} value={warrantyType}>
              <Space direction='horizontal' style={{ marginTop: '5px' }} >
                <Radio value="IW">保内</Radio>
                <Radio value="OOT">保外</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <div><span style={{ color: 'red', marginRight: '5px' }}>*</span><span>产品类型/型号</span></div>}
            rules={[{ required: false, message: '请先选择产品类型/型号' }]}
          >
            <Space style={{ width: '100%' }} direction="vertical">
              <Button size="large" style={{ width: '100%', textAlign: 'left', paddingLeft: '10px', color: `${product.length === 0 ? '#CCCCCC' : ''}` }}
                onClick={() => this.onProductButtonClicked(products)}
              >
                {product.length === 0 ? '请选择产品' : product.join(' / ')}
              </Button>
              <Picker
                columns={productOptions}
                visible={productPickerVisible}
                onClose={() => {
                  this.setState({ productPickerVisible: false })
                }}
                onConfirm={val => this.onProductConfirm(val)}
              >
              </Picker>
              <Button size="large" style={{ width: '100%', textAlign: 'left', paddingLeft: '10px', color: `${spec.length === 0 ? '#CCCCCC' : ''}` }}
                onClick={() => this.onSpecButtonClicked(product, products)}
              >
                {spec.length === 0 ? '请选择型号' : spec.join(' / ')}
              </Button>
              <Picker
                columns={specOptions}
                visible={specPickerVisible}
                onClose={() => {
                  this.setState({ specPickerVisible: false })
                }}
                onConfirm={val => this.onSpecConfirm(val)}
              >
              </Picker>
            </Space>
          </Form.Item>
          <Form.Item
            name='qty'
            label='数量'
            size="large"
            rules={[{ required: true, message: '数量不能为空' }]}
          >
            <Input className="border_input" type="number" max="99" min="1" placeholder='请输入产品数量'></Input>
          </Form.Item>
          <Form.Item
            label='期望上门日期'
          >
            <Button size="large" style={{ width: '100%', textAlign: 'left', paddingLeft: '10px', color: `${serviceDate === null ? '#CCCCCC' : ''}` }}
              onClick={() => this.onDateButtonClicked()}
            >
              {serviceDate === null ? '请选择期望上门日期' : serviceDate}
            </Button>
            <DatePicker
              min={resultDay}
              visible={datePickerVisible}
              onClose={() => {
                this.setState({ datePickerVisible: false })
              }}
              precision='day'
              onConfirm={val => this.onDateConfirm(val)}
            />
          </Form.Item>

          <Form.Item
            name='description'
            label='服务描述'
          >
            <TextArea className="border_textarea" rows="3" placeholder="请输入服务描述"></TextArea>
          </Form.Item>
        </Form>
      </div >
    )
  }
}
