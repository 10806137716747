import './App.css';
import Order from './pages/Order'
function App() {
  return (
    <div>
        <Order/>
    </div>
  );
}

export default App;
